import React, { useEffect, useState } from 'react';
import { Button, Layout, Menu, Modal, Spin, notification } from 'antd';
import { isMobile } from 'react-device-detect';
import { FaUser } from 'react-icons/fa';
import { MdOutlineTipsAndUpdates } from "react-icons/md";
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { AppstoreOutlined, ExclamationCircleFilled, MailOutlined, MenuFoldOutlined, MenuUnfoldOutlined, SettingOutlined, SignalFilled, YoutubeFilled } from '@ant-design/icons';
import { useSocket } from '../../../context/socket';
import { setOpenPayModal, setSiderCollapsed } from '../../../redux/app/appSlice';

import "./SideBar.scss";

const { Sider } = Layout;

function SideBar() {

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const socket = useSocket();

  // const { dataKey } = useParams();

  const siderCollapsed = useSelector((state) => state.app.siderCollapsed);
  const user = useSelector((state) => state.auth.user);

  const [current, setCurrent] = useState("");

  const items = [
    {
      key: 'advice',
      label: <span className="font-semibold text-[16px]">Get Advice</span>,
      icon: <MdOutlineTipsAndUpdates />,
      children: [
        {
          key: 'actionable',
          label: <div className='flex justify-between items-center'><span>Actionable</span> {current == 'actionable' && <Spin size='small' />}</div>,
        },
        {
          key: 'empathetic',
          label: <div className='flex justify-between items-center'><span>Empathetic</span> {current == 'empathetic' && <Spin size='small' />}</div>,
        },
        {
          key: 'perspectiveShifting',
          label: <div className='flex justify-between items-center'><span>Perspective-Shifting</span> {current == 'perspectiveShifting' && <Spin size='small' />}</div>,
        },
        {
          key: 'thoughtProvoking',
          label: <div className='flex justify-between items-center'><span>Thought Provoking</span> {current == 'thoughtProvoking' && <Spin size='small' />}</div>,
        },
        {
          key: 'philosophical',
          label: <div className='flex justify-between items-center'><span>Philosophical</span> {current == 'philosophical' && <Spin size='small' />}</div>,
        },
        {
          key: 'selfReflection',
          label: <div className='flex justify-between items-center'><span>Self-Reflection</span> {current == 'selfReflection' && <Spin size='small' />}</div>,
        },
        {
          key: 'spiritual',
          label: <div className='flex justify-between items-center'><span>Spiritual</span> {current == 'spiritual' && <Spin size='small' />}</div>,
        },
      ],
    },
    {
      key: 'analysis',
      label: <span className="font-semibold text-[16px]">Analysis</span>,
      icon: <SignalFilled />,
      children: [
        {
          key: 'worldview',
          label: <div className='flex justify-between items-center'><span>Worldview Analysis</span> {current == 'worldview' && <Spin size='small' />}</div>,
        },
        {
          key: 'decisionMaking',
          label: <div className='flex justify-between items-center'><span>Decision Making</span> {current == 'decisionMaking' && <Spin size='small' />}</div>,
        },
        {
          key: 'habits',
          label: <div className='flex justify-between items-center'><span>Habits Analysis</span> {current == 'habits' && <Spin size='small' />}</div>,
        },
        {
          key: 'speculative',
          label: <div className='flex justify-between items-center'><span>Speculative Analysis</span> {current == 'speculative' && <Spin size='small' />}</div>,
        },
        {
          key: 'cognitiveDistortion',
          label: <div className='flex justify-between items-center'><span>Cognitive Distortion Analysis</span> {current == 'cognitiveDistortion' && <Spin size='small' />}</div>,
        },
        {
          key: 'relationship',
          label: <div className='flex justify-between items-center'><span>Relationship Analysis</span> {current == 'relationship' && <Spin size='small' />}</div>,
        },
        {
          key: 'intent',
          label: <div className='flex justify-between items-center'><span>Intent Analysis</span> {current == 'intent' && <Spin size='small' />}</div>,
        },
        {
          key: 'valuesBelief',
          label: <div className='flex justify-between items-center'><span>Values and Belief Analysis</span> {current == 'valuesBelief' && <Spin size='small' />}</div>,
        },
        {
          key: 'stressCoping',
          label: <div className='flex justify-between items-center'><span>Stress and Coping Analysis</span> {current == 'stressCoping' && <Spin size='small' />}</div>,
        },
      ],
    },
    {
      key: 'personality',
      label: <span className="font-semibold text-[16px]">Personality</span>,
      icon: <FaUser />,
      children: [
        {
          key: 'personalityArchetype',
          label: <div className='flex justify-between items-center'><span>Personality Archetype Analysis</span> {current == 'personalityArchetype' && <Spin size='small' />}</div>,
        },
        {
          key: 'psychodynamic',
          label: <div className='flex justify-between items-center'><span>Psychodynamic Analysis</span> {current == 'psychodynamic' && <Spin size='small' />}</div>,
        },
        {
          key: 'attachmentStyle',
          label: <div className='flex justify-between items-center'><span>Attachment Style Analysis</span> {current == 'attachmentStyle' && <Spin size='small' />}</div>,
        },
        {
          key: 'moralEthicalReasoning',
          label: <div className='flex justify-between items-center'><span>EmpatheticMoral and Ethical Reasoninspang Analysis</span> {current == 'moralEthicalReasoning' && <Spin size='small' />}</div>,
        },
        {
          key: 'valuesMotivational',
          label: <div className='flex justify-between items-center'><span>Values and Motivational Analysis</span> {current == 'valuesMotivational' && <Spin size='small' />}</div>,
        },
        {
          key: 'emotionalIntelligence',
          label: <div className='flex justify-between items-center'><span>EmpatheticEmotional Intelligence (EQ) Analysis</span> {current == 'emotionalIntelligence' && <Spin size='small' />}</div>,
        },
        {
          key: 'psychologicalNeeds',
          label: <div className='flex justify-between items-center'><span>EmpatheticShadow Personality Analysis Traits</span> {current == 'psychologicalNeeds' && <Spin size='small' />}</div>,
        },
        {
          key: 'shadowPersonality',
          label: <div className='flex justify-between items-center'><span>Psychological Needs Analysis</span> {current == 'shadowPersonality' && <Spin size='small' />}</div>,
        },
        {
          key: 'socialIdentity',
          label: <div className='flex justify-between items-center'><span>Social Identity Analysis</span> {current == 'socialIdentity' && <Spin size='small' />}</div>,
        },
        {
          key: 'situationalPersonality',
          label: <div className='flex justify-between items-center'><span>Situational Personality Analysis</span> {current == 'situationalPersonality' && <Spin size='small' />}</div>,
        },
      ],
    },
    {
      key: 'content',
      label: <span className="font-semibold text-[16px]">Related Content</span>,
      icon: <YoutubeFilled />,
      children: [
        {
          key: 'blogPosts',
          label: <div className='flex justify-between items-center'><span>Blog Posts</span> {current == 'blogPosts' && <Spin size='small' />}</div>,
        },
        {
          key: 'videos',
          label: <div className='flex justify-between items-center'><span>Videos</span> {current == 'videos' && <Spin size='small' />}</div>,
        },
        {
          key: 'podcasts',
          label: <div className='flex justify-between items-center'><span>Podcasts</span> {current == 'podcasts' && <Spin size='small' />}</div>,
        },
        {
          key: 'researchArticles',
          label: <div className='flex justify-between items-center'><span>Research Articles</span> {current == 'researchArticles' && <Spin size='small' />}</div>,
        },
      ],
    },
  ];

  const onClick = (e) => {
    // console.log('click ', e.key);
    if (current) {
      return notification.info({
        description: 'Please wait for the current analysis to finish.',
      });
    }
    setCurrent(e.key);
    // setOpenModal(`/data/${e.key}`);
    // navigate(`/data/${e.key}`);
    socket.emit('analysisMessage', { dataKey: e.key });
    if(isMobile) {
      dispatch(setSiderCollapsed(true));
    }
  };

  const setOpenModal = (to) => {
    if (user.activeSubscription && (user.activeSubscription.status != 'active' && user.activeSubscription.status != 'trialing')) {
      Modal.confirm({
        title: 'Your last invoice payment was failed!',
        icon: <ExclamationCircleFilled />,
        content: 'Will you try to pay the invoice again?',
        okText: "Yes",
        cancelText: "No",
        onOk() {
          window.open(user.activeSubscription.hosted_invoice, '_blank');
          return new Promise((resolve, reject) => {
            setTimeout(resolve, 1000);
          }).catch(() => console.log('Oops errors!'));
        },
        onCancel() {
          console.log('Cancel');
          dispatch(setOpenPayModal(to));
        },
      });
    } else if (user.activeSubscription && (user.activeSubscription.status == 'active' || user.activeSubscription.status === 'trialing')) {
      navigate(to);
    } else {
      dispatch(setOpenPayModal(to));
    }
  };

  /* useEffect(() => {
    if (dataKey) {
      setCurrent(dataKey);
    }
  }, [dataKey]); */

  useEffect(() => {
    if (socket && current) {
      socket.on('_analysisMessage', (data) => {
        setCurrent("");
      });
    }
    return () => {
      if (socket) {
        socket.off('_analysisMessage');
      }
    };
  }, [socket, current]);

  return (
    <Sider
      style={{
        overflow: 'auto',
        height: '100vh',
        position: 'fixed',
        insetInlineStart: 0,
        top: 0,
        bottom: 0,
        scrollbarWidth: 'thin',
        scrollbarColor: 'unset',
        background: 'white',
        zIndex: 9999,
      }}
      collapsedWidth={0}
      width={isMobile ? "100%" : 288}
      collapsed={siderCollapsed}
      collapsible
      trigger={null}
    >
      <div className='h-16 flex items-center sticky top-0 z-[9999] bg-white'>
        <Button icon={<MenuFoldOutlined />} type='text' className="ml-2" onClick={() => {
          dispatch(setSiderCollapsed(!siderCollapsed));
        }} />
      </div>
      {!siderCollapsed && <Menu
        onClick={onClick}
        className='w-full sidebar-menu'
        defaultOpenKeys={['advice']}
        selectedKeys={[current]}
        mode="inline"
        items={items}
      />}

      {(user.activeSubscription?.status !== 'active' && user.activeSubscription?.status !== 'trialing') && <div className="text-center py-4">
        <Button
          size='large'
          onClick={setOpenModal}
          className="font-semibold w-4/5"
        >Upgrade to PRO</Button>
      </div>}
    </Sider>
  )
}

export default SideBar;