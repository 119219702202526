import { lazy } from 'react';

const routes = [
  {
    path: '',
    component: lazy(() => import('../pages/Home/Home')),
    exact: true,
    requireAuthLayout: false,
    // isPayed: true,
  },
  {
    path: 'home',
    component: lazy(() => import('../pages/Home/Home')),
    exact: true,
    requireAuthLayout: false,
    // isPayed: true,
  },
  /* {
    path: 'pricing',
    component: lazy(() => import('../pages/Home/Pricing')),
    exact: true,
    requireAuthLayout: false,
  }, */
  /* {
    path: 'plans',
    component: lazy(() => import('../pages/Price/Lists')),
    exact: true,
    requireAuthLayout: true,
  }, */
  {
    path: 'playground',
    component: lazy(() => import('../pages/Home/PlaygroundText')),
    exact: true,
    requireAuthLayout: true,
    // isPayed: true
  },
  {
    path: 'voice-playground',
    component: lazy(() => import('../pages/Home/PlaygroundVoice')),
    exact: true,
    requireAuthLayout: true,
    // isPayed: true
  },
  /* {
    path: 'data/:dataKey',
    component: lazy(() => import('../pages/Home/AnalysisView')),
    exact: true,
    requireAuthLayout: true,
    // isPayed: true
  }, */
  {
    path: 'playground/:id',
    component: lazy(() => import('../pages/Home/PlaygroundText')),
    isAdmin: true,
    exact: true,
    requireAuthLayout: true,
    // isPayed: true
  },
  {
    path: 'user/profile',
    component: lazy(() => import('../pages/Profile/Edit')),
    exact: true,
    requireAuthLayout: true,
  },
  {
    path: 'admin/users',
    component: lazy(() => import('../pages/Admin/Users')),
    exact: true,
    isAdmin: true,
    requireAuthLayout: true,
  },
  {
    path: 'admin/dashboard',
    component: lazy(() => import('../pages/Admin/Dashboard')),
    exact: true,
    isAdmin: true,
    requireAuthLayout: true,
  },
  {
    path: 'admin/conversations',
    component: lazy(() => import('../pages/Admin/Conversations')),
    exact: true,
    isAdmin: true,
    requireAuthLayout: true,
  },
  {
    path: 'admin/analysis/:id',
    component: lazy(() => import('../pages/Admin/Analysis')),
    exact: true,
    isAdmin: true,
    requireAuthLayout: true,
  },
];

export default routes;
